<template>
	<div class="pt-3 pl-5 pr-5">
		<div class="row w-full mb-3 justify-between flex" style="align-items: center">
			<div class="title flex items-center">
				<h1>Document Approvals</h1>
			</div>
			<div class="cardbody">
				<div class="flex items-center">
					<button class="flex" :class="presentDocumentData.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'" @click="filterOpen">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
				</div>
			</div>
		</div>
		<div class="mt-3 headerText">
			Document management, often referred to as Document Management Systems(DMS), is to use of a computer system and software to store, manage and track electronic documents and electronic images of paper-based information captured through the use of a document scanner.Document management is
			how your organization stores, manages, and tracks its electronic documents.According to ISO 12651-2, a document is "recorded information or object which can be treadted as a unit."
		</div>
		<newTable :tableRowData="presentDocumentData" :headersData="Headers" :loadData="dataLoading"></newTable>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '../main';
import newTable from '@/components/newTable.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
	data(): any {
		return {
			Headers: ['documentId', 'documentName', 'version', 'type', 'classification', 'statusCode'],
			allDocumentsData: [],
			presentDocumentData: [],
			showFilters: false,
			dataLoading: true,
		};
	},
	components: {
		newTable
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	async mounted() {
		this.dataLoading = true;
		// this.$store.dispatch('updateDotsLoading', true);
		await this.getAllDocumentsList();
		emitter.on('rowData', (data: any) => {
			let id = data._id;
			this.$router.push({ name: 'editor', params: { documentId: id, type: 'approvals' } });
		});
		this.dataLoading = false;
	},

	methods: {
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		async getAllDocumentsList() {
			this.dataLoading = true;
			await this.$http.get(`${process.env.VUE_APP_DMS_API_URL}/api/org/documents/getAll`).then((res: any) => {
				this.allDocumentsData = res.data;

				let forApproval = this.allDocumentsData.filter((obj: any) => {
					if (Object.keys(obj).includes('approvals') && obj.approvals) {
						const lastApproverObj = obj.approvals[obj.approvals.length - 1];
						// console.log("lastApproverObj",lastApproverObj)
						// && lastApproverObj.approval === this.userInfo._id
						if (lastApproverObj) {
							return obj.statusCode == 10415 || obj.statusCode == 10408;
						} else {
							console.log("The last approver's ID does not match this user's ID");
						}
					}
				});
				console.log("forApproval",forApproval)
				this.presentDocumentData = forApproval;
				// this.$store.dispatch('updateDotsLoading', false);
				this.dataLoading = false;
			});
		}
	}
});
</script>
<style scoped>
.container {
	display: flex;
	justify-content: space-between;
}
.insideContainer {
	display: flex;
}
.scroll {
	overflow: hidden;
}
</style>